import React from "react";

function Contact() {
  return (
    <div
      id="contact"
      className="py-8 sm:p-16 w-full text-center"
      style={{
        paddingTop: "96px",
      }}
    >
      <h1 className="text-3xl sm:text-5xl font-medium">
        {" "}
        Get Consultation Call
      </h1>
      <p className="font-light mt-2 text-xl">Get in touch via the form below</p>
      <div className="mt-5">
        <form className="max-w-sm mx-auto">
          <div className="mb-5">
            <input
              type="email"
              id="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              placeholder="Name"
              required
            />
          </div>
          <div className="mb-5">
            <input
              type="text"
              placeholder="Phone"
              id="phone"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              required
            />
          </div>
          <div className="mb-5">
            <input
              type="text"
              placeholder="City"
              id="city"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              required
            />
          </div>

          <textarea
            id="message"
            rows="4"
            className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Leave a message..."
          ></textarea>

          <button
            type="submit"
            className="text-white bg-[#FFA500] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Submit
          </button>
        </form>
      </div>
      <h1 className="text-2xl mt-5">
        or by emailing{" "}
        <a href="mailto:adv.sameerbaig@gmail.com" className="text-[orange]">
          adv.sameerbaig@gmail.com.
        </a>
      </h1>
    </div>
  );
}

export default Contact;
