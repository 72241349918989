import React from "react";

const feedbacks = [
  {
    name: "Sameer Khan",
    designation: "Student",
    description:
      "This lawyer provided excellent legal services, helping us win a critical case. Highly recommended!",
    rate: 5,
  },
  {
    name: "Owes Khan",
    designation: "IT Professional",
    description:
      "Outstanding service with attention to detail. Helped us navigate complex legal challenges.",
    rate: 5,
  },
  {
    name: "Sohail Ali Malik",
    designation: "Founder, SAMS INFOSOFT",
    description:
      "Professional, diligent, and always available for advice. A great legal partner.",
    rate: 4,
  },
];

function Testimonials() {
  return (
    <div
      id="testimonial"
      className="py-8 sm:p-16 w-full text-center"
      key={"testimonial"}
      style={{
        paddingTop: "96px",
      }}
    >
      <h1 className="text-4xl sm:text-5xl font-medium">Clients Feedback</h1>
      <div className="py-8 sm:p-8 grid grid-cols-1 sm:grid-cols-3 gap-4">
        {feedbacks.map((feedback) => {
          return (
            <div
              key={feedback.name}
              className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <h5 className="text-left text-2xl font-medium tracking-tight text-gray-900 dark:text-white">
                {feedback.name}
              </h5>
              <h6 className="text-left text-md tracking-tight text-gray-900 dark:text-white">
                {feedback.designation}
              </h6>
              <div className="flex gap-0 mb-2">
                {[...Array(feedback.rate)].map((i) => {
                  return (
                    <svg
                      key={i}
                      className="w-[20px] h-[20px] text-[#EDCB06] dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                    </svg>
                  );
                })}
              </div>
              <p className="font-normal text-gray-700 dark:text-gray-400 text-left">
                {feedback.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Testimonials;
