import React from "react";

function About() {
  return (
    <div
      id="about"
      className="py-8 sm:p-16"
      style={{
        paddingTop: "96px",
      }}
    >
      <h1 className="text-4xl sm:text-6xl font-semibold mb-6">
        "Justice Served,
        <br /> Rights Protected."
      </h1>
      <div className="font-light text-2xl sm:text-3xl w-full sm:w-[70%]">
        <p className="mb-6">Welcome to my legal practice.</p>
        <p className="mb-6">
          {" "}
          I am a dedicated and passionate lawyer, fresh into the profession with
          a strong commitment to delivering exceptional legal services.
        </p>
        <p className="mb-6">
          {" "}
          My approach is rooted in a deep understanding of the law, combined
          with a fresh perspective and innovative thinking.
        </p>
        <p className="mb-6">
          {" "}
          I believe in providing personalized attention to each client, ensuring
          that your legal matters are handled with the utmost care and
          professionalism.
        </p>
        <p className="mb-6">
          {" "}
          Whether you need guidance on civil matters, criminal defense,
          corporate law, or any other legal issue, I am here to assist you with
          integrity and determination. My goal is to protect your rights,
          provide clear and honest legal advice, and represent your interests to
          the fullest extent.
        </p>
        <p className="mb-6">
          Let's work together to navigate the complexities of the legal system
          and achieve the best possible outcome for your case.
        </p>
      </div>
      <a href="#contact">
        <button className="relative rounded-full bg-[#FFA500] px-4 py-2 font-mono font-bold text-black transition-colors duration-300 ease-linear before:absolute before:right-1/2 before:top-1/2 before:-z-[1] before:h-3/4 before:w-2/3 before:origin-bottom-left before:-translate-y-1/2 before:translate-x-1/2 before:animate-ping before:rounded-full before:bg-[#FFA500] hover:bg-[#FFA500] hover:before:bg-[#FFA500]">
          {"Get Consultation"}
        </button>
      </a>
    </div>
  );
}

export default About;
