import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../providers/ThemeProvider";

function Navbar({ showConnectButton }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleButton = () => {
    setOpen(!open);
  };
  const [selected, setSelected] = useState("home");
  const { theme, handleTheme } = useTheme();
  const buttonColorSelector = (name) => {
    if (selected === name) {
      return "text-white bg-[#FFA500]";
    } else {
      return "text-gray-900";
    }
  };
  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div
        className="z-10 fixed w-full flex flex-wrap items-center justify-between mx-auto p-2 sm:p-4"
        style={{
          // backgroundColor: theme === "light" ? "white" : "black",
          backgroundColor: "transparent",
          backdropFilter: "blur(10px)",
          color: theme !== "light" ? "white" : "black",
          transition: "background-color 0.5s, color 0.5s",
        }}
      >
        <a href="/" className="flex items-center rtl:space-x-reverse">
          {/* <img
            src="https://flowbite.com/docs/images/logo.svg"
            className="h-8"
            alt="Flowbite Logo"
          /> */}
          <span className="hidden sm:block self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            Advocate
          </span>
          <span className="sm:ml-3 self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            Sameer Baig
          </span>
        </a>
        <div></div>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse sm:gap-4">
          <button
            onClick={() => handleTheme(theme === "light" ? "dark" : "light")}
            type="button"
            class=" border border-[#FFA500] hover:text-white font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
          >
            {theme === "light" ? (
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                // fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.675 2.015a.998.998 0 0 0-.403.011C6.09 2.4 2 6.722 2 12c0 5.523 4.477 10 10 10 4.356 0 8.058-2.784 9.43-6.667a1 1 0 0 0-1.02-1.33c-.08.006-.105.005-.127.005h-.001l-.028-.002A5.227 5.227 0 0 0 20 14a8 8 0 0 1-8-8c0-.952.121-1.752.404-2.558a.996.996 0 0 0 .096-.428V3a1 1 0 0 0-.825-.985Z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                class="w-6 h-6 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M13 3a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0V3ZM6.343 4.929A1 1 0 0 0 4.93 6.343l1.414 1.414a1 1 0 0 0 1.414-1.414L6.343 4.929Zm12.728 1.414a1 1 0 0 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 1.414 1.414l1.414-1.414ZM12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-9 4a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H3Zm16 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2ZM7.757 17.657a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414Zm9.9-1.414a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM13 19a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Z"
                  clip-rule="evenodd"
                />
              </svg>
            )}
            <span class="sr-only">Icon description</span>
          </button>
          {showConnectButton && (
            <button
              onClick={() => {
                navigate("/contacts");
              }}
              class="bg-[#FFA500] text-black font-bold py-2 px-4 rounded-full animate-pulse"
            >
              Connect
            </button>
          )}
          {showConnectButton && (
            <button
              onClick={handleButton}
              // data-collapse-toggle="navbar-cta"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-cta"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          )}
        </div>
        <div
          className={`${
            open ? "" : "hidden"
          } items-center justify-between w-full md:flex md:w-auto md:order-1`}
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li
              onClick={() => {
                setOpen(false);
                setSelected("home");
              }}
            >
              <a
                href="/"
                className={`font-bold text-lg block py-2 px-3 md:p-0 md:bg-transparent md:text-gray-900 ${buttonColorSelector(
                  "home"
                )} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                aria-current="page"
                style={{
                  color: theme !== "light" ? "white" : "black",
                  transition: "color 0.5s ease",
                }}
              >
                Home
              </a>
            </li>
            <li
              onClick={() => {
                setOpen(false);
                setSelected("about");
              }}
            >
              <a
                href="#about"
                className={`font-bold text-lg block py-2 px-3 md:p-0 md:bg-transparent md:text-gray-900 ${buttonColorSelector(
                  "about"
                )} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                style={{
                  color: theme !== "light" ? "white" : "black",
                  transition: "color 0.5s ease",
                }}
              >
                About
              </a>
            </li>
            <li
              onClick={() => {
                setOpen(false);
                setSelected("practice");
              }}
            >
              <a
                href="#practice"
                className={`font-bold text-lg block py-2 px-3 md:p-0 md:bg-transparent md:text-gray-900 ${buttonColorSelector(
                  "practice"
                )} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                style={{
                  color: theme !== "light" ? "white" : "black",
                  transition: "color 0.5s ease",
                }}
              >
                Practice
              </a>
            </li>
            <li
              onClick={() => {
                setOpen(false);
                setSelected("testimonial");
              }}
            >
              <a
                href="#testimonial"
                className={`font-bold text-lg block py-2 px-3 md:p-0 md:bg-transparent md:text-gray-900 ${buttonColorSelector(
                  "testimonial"
                )} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                style={{
                  color: theme !== "light" ? "white" : "black",
                  transition: "color 0.5s ease",
                }}
              >
                Testimonial
              </a>
            </li>
            <li
              onClick={() => {
                setOpen(false);
                setSelected("contact");
              }}
            >
              <a
                href="#contact"
                className={`font-bold text-lg block py-2 px-3 md:p-0 md:bg-transparent md:text-gray-900 ${buttonColorSelector(
                  "contact"
                )} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                style={{
                  color: theme !== "light" ? "white" : "black",
                  transition: "color 0.5s ease",
                }}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
