import "./App.css";
// import MuiAlert from "@mui/material/Alert";
// import Snackbar from "@mui/material/Snackbar";
import Navbar from "./components/Navbar";
// import { forwardRef } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import React, { useEffect, useState } from "react";
import Contacts from "./pages/Contacts";
import Footer from "./components/Footer";
import { useTheme } from "./providers/ThemeProvider";
// const Alert = forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

function App() {
  // const loader = useSelector((state) => state.loader.value);
  // const snackbar = useSelector((state) => state.snackbar);
  // const dispatch = useDispatch();

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   dispatch(triggerSnackbar({ show: false }));
  // };
  const [showConnectButton, setShowConnectButton] = useState(true);
  const { theme, handleTheme } = useTheme();
  const handlePreferredTheme = () => {
    let preferredTheme = localStorage.getItem("preferred-theme");
    if (!preferredTheme) {
      console.log("inside");
      let isPreferredDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      handleTheme(isPreferredDark ? "dark" : "light");
    } else {
      handleTheme(preferredTheme);
    }
  };
  useEffect(() => {
    handlePreferredTheme();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      {/* {loader ? <Loader /> : null} */}
      <Navbar showConnectButton={showConnectButton} />
      <div
        className="px-8 py-16 sm:px-16"
        style={{
          backgroundColor: theme === "dark" ? "black" : "#F5F5F5",
          color: theme === "dark" ? "white" : "black",
          transition: "background-color 0.5s ease, color 0.5s ease",
        }}
        // style={{ backgroundColor: colorPalette.body.backgroundColor }}
      >
        <Routes>
          <Route
            exact
            path="/"
            element={<Home setShowConnectButton={setShowConnectButton} />}
          />
          <Route
            exact
            path="/contacts"
            element={<Contacts setShowConnectButton={setShowConnectButton} />}
          />
        </Routes>
        {/* <Snackbar
          open={snackbar.show}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbar.type}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar> */}
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default App;
